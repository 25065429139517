// src/pages/leaveManagement/LeaveApplication.tsx

import React, { useState } from 'react';
import { Row, Col, Card, Form, Button, Table, Modal, Pagination } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';

interface LeaveEntry {
  id: number;
  leaveCode: string;
  leaveType: string;
  status: string;
  fromDate: string;
  toDate: string;
  noOfDays: string;
  description: string;
}

const initialData: LeaveEntry[] = [
  {
    id: 1,
    leaveCode: 'Leave Code 1',
    leaveType: 'Full Day',
    status: 'Approved',
    fromDate: '2024-07-25',
    toDate: '2024-07-30',
    noOfDays: '5',
    description: 'Vacation',
  },
  {
    id: 2,
    leaveCode: 'Leave Code 2',
    leaveType: 'Half Day',
    status: 'Pending',
    fromDate: '2024-08-01',
    toDate: '2024-08-01',
    noOfDays: '0.5',
    description: 'Doctor Appointment',
  },
  // Additional data for demonstrating pagination
];

const LeaveApplication: React.FC = () => {
  const [leaveCode, setLeaveCode] = useState('');
  const [leaveType, setLeaveType] = useState('');
  const [status, setStatus] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [noOfDays, setNoOfDays] = useState('');
  const [description, setDescription] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalPurpose, setModalPurpose] = useState<'edit' | 'history' | null>(null);
  const [selectedEntry, setSelectedEntry] = useState<LeaveEntry | null>(null);
  const [leaveData, setLeaveData] = useState<LeaveEntry[]>(initialData);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Items per page for pagination

  const handleSearch = () => {
    console.log('Search:', { leaveCode, leaveType, status, fromDate, toDate });
  };

  const handleSave = () => {
    if (isEditMode && selectedEntry) {
      // Update the existing entry
      setLeaveData(leaveData.map(entry => entry.id === selectedEntry.id ? {
        ...entry,
        leaveCode,
        leaveType,
        fromDate,
        toDate,
        noOfDays,
        description
      } : entry));
    } else {
      // Add a new entry
      const newEntry: LeaveEntry = {
        id: leaveData.length + 1,
        leaveCode,
        leaveType,
        status: 'Pending', // Default status
        fromDate,
        toDate,
        noOfDays,
        description,
      };
      setLeaveData([...leaveData, newEntry]);
    }
    setShowModal(false); // Close modal after save
    handleClear(); // Clear form fields
  };

  const handleClear = () => {
    setLeaveCode('');
    setLeaveType('');
    setFromDate('');
    setToDate('');
    setNoOfDays('');
    setDescription('');
    setIsEditMode(false);
  };

  const handleEdit = (entry: LeaveEntry) => {
    setSelectedEntry(entry);
    setLeaveCode(entry.leaveCode);
    setLeaveType(entry.leaveType);
    setFromDate(entry.fromDate);
    setToDate(entry.toDate);
    setNoOfDays(entry.noOfDays);
    setDescription(entry.description);
    setIsEditMode(true);
    setModalPurpose('edit');
    setShowModal(true);
  };

  const handleHistory = (entry: LeaveEntry) => {
    setSelectedEntry(entry);
    setModalPurpose('history');
    setShowModal(true);
  };

  const renderModalContent = () => {
    if (modalPurpose === 'edit') {
      return (
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Leave Code</Form.Label>
                <Form.Select value={leaveCode} onChange={(e) => setLeaveCode((e.target as HTMLSelectElement).value)}>
                  <option>Select Leave Code</option>
                  <option>Leave Code 1</option>
                  <option>Leave Code 2</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Leave Type</Form.Label>
                <Form.Select value={leaveType} onChange={(e) => setLeaveType((e.target as HTMLSelectElement).value)}>
                  <option>Select Leave Type</option>
                  <option>Full Day</option>
                  <option>Half Day</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>From Date</Form.Label>
                <Form.Control
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>To Date</Form.Label>
                <Form.Control
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>No of Days</Form.Label>
                <Form.Control
                  type="number"
                  value={noOfDays}
                  onChange={(e) => setNoOfDays(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      );
    } else if (modalPurpose === 'history' && selectedEntry) {
      return (
        <div>
          <p><strong>Leave Code:</strong> {selectedEntry.leaveCode}</p>
          <p><strong>Leave Type:</strong> {selectedEntry.leaveType}</p>
          <p><strong>From Date:</strong> {selectedEntry.fromDate}</p>
          <p><strong>To Date:</strong> {selectedEntry.toDate}</p>
          <p><strong>No of Days:</strong> {selectedEntry.noOfDays}</p>
          <p><strong>Description:</strong> {selectedEntry.description}</p>
        </div>
      );
    }
    return null;
  };

  // Pagination Logic
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItems = leaveData.slice(firstItemIndex, lastItemIndex);
  const totalPages = Math.ceil(leaveData.length / itemsPerPage);

  return (
    <div className="leave-application">
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-4">
                  <FeatherIcon icon="filter" style={{ marginRight: '10px' }} />
                  Leave Application Filter
                </h5>
                <Button variant="primary" className="mb-3" onClick={() => { setIsEditMode(false); setModalPurpose('edit'); setShowModal(true); }}>
                  <FeatherIcon icon="plus" /> Add Leave
                </Button>
              </div>
              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Leave Code</Form.Label>
                      <Form.Select value={leaveCode} onChange={(e) => setLeaveCode((e.target as HTMLSelectElement).value)}>
                        <option>Select Leave Code</option>
                        <option>Leave Code 1</option>
                        <option>Leave Code 2</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Leave Type</Form.Label>
                      <Form.Select value={leaveType} onChange={(e) => setLeaveType((e.target as HTMLSelectElement).value)}>
                        <option>Select Leave Type</option>
                        <option>Type 1</option>
                        <option>Type 2</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <Form.Select value={status} onChange={(e) => setStatus((e.target as HTMLSelectElement).value)}>
                        <option>Select Status</option>
                        <option>Approved</option>
                        <option>Pending</option>
                        <option>Rejected</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>From Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>To Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="d-flex align-items-center">
                    <Button variant="primary" onClick={handleSearch} className="mt-3">
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        <Col md={12} className="mt-4">
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-4">
                  <FeatherIcon icon="list" style={{ marginRight: '10px' }} />
                  Leave Application List
                </h5>
                <div>
                  <Button variant="link" className="me-2">
                    <FeatherIcon icon="file-text" /> Excel
                  </Button>
                  <Button variant="link">
                    <FeatherIcon icon="file-text" /> PDF
                  </Button>
                </div>
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>Leave Code</th>
                    <th>Leave Type</th>
                    <th>Status</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map(entry => (
                    <tr key={entry.id}>
                      <td>{entry.id}</td>
                      <td>{entry.leaveCode}</td>
                      <td>{entry.leaveType}</td>
                      <td>{entry.status}</td>
                      <td>{entry.fromDate}</td>
                      <td>{entry.toDate}</td>
                      <td>
                        <Button variant="link" onClick={() => handleEdit(entry)}>
                          <FeatherIcon icon="edit" />
                        </Button>
                        <Button variant="link" onClick={() => handleHistory(entry)}>
                          <FeatherIcon icon="clock" />
                        </Button>
                        <Button variant="link">
                          <FeatherIcon icon="x" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination className="justify-content-center">
                <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
                <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
                {[...Array(totalPages)].map((_, pageIndex) => (
                  <Pagination.Item
                    key={pageIndex + 1}
                    active={pageIndex + 1 === currentPage}
                    onClick={() => setCurrentPage(pageIndex + 1)}
                  >
                    {pageIndex + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages} />
                <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modalPurpose === 'edit' ? 'Edit Leave Application' : 'Leave Application History'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={8}>
              {renderModalContent()}
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Leave Code</th>
                        <th>Leave Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Leave Code 1</td>
                        <td>5</td>
                      </tr>
                      <tr>
                        <td>Leave Code 2</td>
                        <td>10</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        {modalPurpose === 'edit' && (
          <Modal.Footer>
            <Button variant="primary" onClick={handleSave}>Save</Button>
            <Button variant="secondary" onClick={handleClear}>Clear</Button>
            <Button variant="danger" onClick={() => setShowModal(false)}>Close</Button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default LeaveApplication;
