import React, { useState } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "../../components/PageTitle";
import companyseetup from "../../assets/images/company-setup.png";

interface CompanyConfigurationFormData {
  companyName: string;
  companyType: string;
  companySize: string;
  businessNature: string;
}

interface AddressDetailsFormData {
  address1: string;
  address2: string;
  country: string;
  state: string;
  pinCode: string;
  landmark: string;
}

interface ContactDetailsFormData {
  contactPerson: string;
  mobileNo: string;
  phoneNo: string;
  email: string;
  pinCode: string;
  panNo: string;
  tanNo: string;
}

const CompanyConfigurationForm = ({ onNext }: { onNext: () => void }) => {
  const schema = yup.object().shape({
    companyName: yup.string().required("Please enter your company name"),
    companyType: yup.string().required("Please select a company type"),
    companySize: yup.string().required("Please select a company size"),
    businessNature: yup.string().required("Please enter the nature of business"),
  });

  const { register, handleSubmit, formState: { errors } } = useForm<CompanyConfigurationFormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data: CompanyConfigurationFormData) => {
    console.log(data);
    onNext();
  };

  return (
    <Card>
      <Card.Body>
        <h4 className="header-title">Company Configuration Setup</h4>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Label>Name of the Company</Form.Label>
            <Form.Control type="text" placeholder="Enter your company name" {...register("companyName")} />
            {errors.companyName && <p>{errors.companyName.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Company Type</Form.Label>
            <div>
              <Form.Check type="radio" label="Proprietor" value="Proprietor" {...register("companyType")} />
              <Form.Check type="radio" label="LP" value="LP" {...register("companyType")} />
              <Form.Check type="radio" label="PVT" value="PVT" {...register("companyType")} />
              <Form.Check type="radio" label="Public" value="Public" {...register("companyType")} />
              {errors.companyType && <p>{errors.companyType.message}</p>}
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Company Size</Form.Label>
            <div>
              <Form.Check type="radio" label="Small" value="Small" {...register("companySize")} />
              <Form.Check type="radio" label="Medium" value="Medium" {...register("companySize")} />
              <Form.Check type="radio" label="Large" value="Large" {...register("companySize")} />
              {errors.companySize && <p>{errors.companySize.message}</p>}
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Nature Of Business</Form.Label>
            <Form.Control type="text" placeholder="Nature of Business" {...register("businessNature")} />
            {errors.businessNature && <p>{errors.businessNature.message}</p>}
          </Form.Group>

          <Button type="submit">Next</Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

const AddressDetailsForm = ({ onNext, onBack }: { onNext: () => void, onBack: () => void }) => {
  const schema = yup.object().shape({
    address1: yup.string().required("Please enter Address 1"),
    address2: yup.string().required("Please enter Address 2"),
    country: yup.string().required("Please enter Country"),
    state: yup.string().required("Please enter State"),
    pinCode: yup.string().required("Please enter PIN Code"),
    landmark: yup.string().required("Please enter Landmark"),
  });

  const { register, handleSubmit, formState: { errors } } = useForm<AddressDetailsFormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data: AddressDetailsFormData) => {
    console.log(data);
    onNext();
  };

  return (
    <Card>
      <Card.Body>
        <h4 className="header-title">Address Details</h4>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Label>Address 1</Form.Label>
            <Form.Control type="text" placeholder="Address 1" {...register("address1")} />
            {errors.address1 && <p>{errors.address1.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Address 2</Form.Label>
            <Form.Control type="text" placeholder="Address 2" {...register("address2")} />
            {errors.address2 && <p>{errors.address2.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Country</Form.Label>
            <Form.Control type="text" placeholder="Country" {...register("country")} />
            {errors.country && <p>{errors.country.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>State</Form.Label>
            <Form.Control type="text" placeholder="State" {...register("state")} />
            {errors.state && <p>{errors.state.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>PIN Code</Form.Label>
            <Form.Control type="text" placeholder="PIN Code" {...register("pinCode")} />
            {errors.pinCode && <p>{errors.pinCode.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Landmark</Form.Label>
            <Form.Control type="text" placeholder="Landmark" {...register("landmark")} />
            {errors.landmark && <p>{errors.landmark.message}</p>}
          </Form.Group>

          <Button variant="secondary" onClick={onBack}>Back</Button>
          <Button type="submit" className="ms-2">Next</Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

const ContactDetailsForm = ({ onBack }: { onBack: () => void }) => {
  const schema = yup.object().shape({
    contactPerson: yup.string().required("Please enter Contact Person"),
    mobileNo: yup.string().required("Please enter Mobile Number"),
    phoneNo: yup.string().required("Please enter Phone Number"),
    email: yup.string().email("Invalid email").required("Please enter Email"),
    pinCode: yup.string().required("Please enter PIN Code"),
    panNo: yup.string().required("Please enter PAN Number"),
    tanNo: yup.string().required("Please enter TAN Number"),
  });

  const { register, handleSubmit, formState: { errors } } = useForm<ContactDetailsFormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data: ContactDetailsFormData) => {
    console.log(data);
  };

  return (
    <Card>
      <Card.Body>
        <h4 className="header-title">Contact Details</h4>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Label>Contact Person</Form.Label>
            <Form.Control type="text" placeholder="Contact Person" {...register("contactPerson")} />
            {errors.contactPerson && <p>{errors.contactPerson.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Mobile No</Form.Label>
            <Form.Control type="text" placeholder="Mobile Number" {...register("mobileNo")} />
            {errors.mobileNo && <p>{errors.mobileNo.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Phone No</Form.Label>
            <Form.Control type="text" placeholder="Phone Number" {...register("phoneNo")} />
            {errors.phoneNo && <p>{errors.phoneNo.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email ID</Form.Label>
            <Form.Control type="email" placeholder="Email" {...register("email")} />
            {errors.email && <p>{errors.email.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>PIN Code</Form.Label>
            <Form.Control type="text" placeholder="PIN Code" {...register("pinCode")} />
            {errors.pinCode && <p>{errors.pinCode.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>PAN No</Form.Label>
            <Form.Control type="text" placeholder="PAN Number" {...register("panNo")} />
            {errors.panNo && <p>{errors.panNo.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>TAN No</Form.Label>
            <Form.Control type="text" placeholder="TAN Number" {...register("tanNo")} />
            {errors.tanNo && <p>{errors.tanNo.message}</p>}
          </Form.Group>

          <Button variant="secondary" onClick={onBack}>Back</Button>
          <Button type="submit" className="ms-2">Submit</Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

const CompanySetupForm = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    setCurrentStep(prevStep => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Company Setup", path: "/pages/companySetup/CompanyDetails", active: true },
        ]}
        title={"Company Setup"}
      />

      <Row>
        <Col xl={8} md={12}>
          {currentStep === 0 && <CompanyConfigurationForm onNext={handleNext} />}
          {currentStep === 1 && <AddressDetailsForm onNext={handleNext} onBack={handleBack} />}
          {currentStep === 2 && <ContactDetailsForm onBack={handleBack} />}
        </Col>
        <Col xl={4} md={12} className="d-flex align-items-center justify-content-center">
          <img src={companyseetup} alt="Company Setup"  style={{ maxWidth: "100%", height: "auto" }} />
        </Col>
      </Row>
    </>
  );
};

export default CompanySetupForm;
