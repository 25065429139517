import React, { useState } from "react";
import { Table, Button, Pagination, Tooltip, OverlayTrigger, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";

const initialCompanies = [
  // Sample data
  {
    id: 1,
    name: "Shree Anand Transport",
    address: "Bangalore Tech Park, Bangalore, 586101, Karnataka, INDIA",
    contact: {
      name: "Naveedns",
      phone: "8151058937",
      email: "naveednyc4@gmail.com",
    },
    active: true,
  },
  {
    id: 2,
    name: "33 Kuber Advisory India",
    address: "306 3rd floor Vikas Center, Vadawali Gao Chembur, Mumbai, 700070, Maharashtra, INDIA",
    contact: {
      name: "Chandran",
      phone: "7656565464",
      email: "Chandran@33kuber.com",
    },
    active: true,
  },
  {
    id: 3,
    name: "Unnamed Road MS Building",
    address: "Bangalore Task Karnataka, Bangalore, 560046, Karnataka, INDIA",
    contact: {
      name: "Chankwinn",
      phone: "8409718735",
      email: "mdirsadtech/305@gmail.com",
    },
    active: false,
  },
  // Add more data as needed
];

const CompanyList: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [activeFilter, setActiveFilter] = useState<'all' | 'active' | 'inactive'>('all');
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
  const companiesPerPage = 5;
  const totalPages = Math.ceil(initialCompanies.length / companiesPerPage);
  const navigate = useNavigate();

  const handleAddCompany = () => {
    navigate('/company-setup/details');
  };

  const handleExportToExcel = () => {
    console.log("Export to Excel");
  };

  const handleExportToPDF = () => {
    console.log("Export to PDF");
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (id: number) => {
    console.log(`Edit company ${id}`);
  };

  const handleDelete = (id: number) => {
    console.log(`Delete company ${id}`);
  };

  const handleApproveReject = (id: number, action: string) => {
    console.log(`${action} company ${id}`);
  };

  const handleViewDetails = (id: number) => {
    console.log(`View details for company ${id}`);
  };

  const handleBranch = (id: number) => {
    console.log(`Manage branches for company ${id}`);
  };

  const handleCompanySettings = (id: number) => {
    console.log(`Settings for company ${id}`);
  };

  const handleStatusChange = (id: number) => {
    const updatedCompanies = initialCompanies.map(company =>
      company.id === id ? { ...company, active: !company.active } : company
    );
    console.log(`Toggled status for company ${id}`);
  };

  const handleToggleFilter = (value: 'all' | 'active' | 'inactive') => {
    setActiveFilter(value);
  };

  const handleSelectCompany = (id: number) => {
    setSelectedCompanies(prevSelected =>
      prevSelected.includes(id) ? prevSelected.filter(companyId => companyId !== id) : [...prevSelected, id]
    );
  };

  const handleBulkStatusChange = (status: boolean) => {
    console.log(`Bulk ${status ? 'activate' : 'deactivate'} companies: ${selectedCompanies}`);
    // Apply bulk status change logic here
  };

  const filteredCompanies = initialCompanies.filter(company => {
    if (activeFilter === 'active') return company.active;
    if (activeFilter === 'inactive') return !company.active;
    return true; // For 'all'
  });

  const currentCompanies = filteredCompanies.slice(
    (currentPage - 1) * companiesPerPage,
    currentPage * companiesPerPage
  );

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between mb-3">
        <Button variant="primary" onClick={handleAddCompany}>
          <FeatherIcon icon="plus" size={16} className="me-2" /> Add New Company
        </Button>
        <div>
          <Button
            variant="success"
            className="me-2"
            onClick={handleExportToExcel}
          >
            <FeatherIcon icon="download" size={16} className="me-2" />
            Export to Excel
          </Button>
          <Button
            variant="danger"
            onClick={handleExportToPDF}
          >
            <FeatherIcon icon="file-text" size={16} className="me-2" />
            Export to PDF
          </Button>
        </div>
      </div>
      <h1><FeatherIcon icon="briefcase" size={24} className="me-2" /> Company List</h1>
      <div className="d-flex justify-content-end mb-3 align-items-center">
        <ToggleButtonGroup type="radio" name="filter" value={activeFilter} onChange={handleToggleFilter}>
          <ToggleButton id="all" value="all">All</ToggleButton>
          <ToggleButton id="active" value="active">Active</ToggleButton>
          <ToggleButton id="inactive" value="inactive">Inactive</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="table-box">
        <Table striped bordered hover responsive>
          <thead className="bg-primary text-white">
            <tr>
              <th>Select</th>
              <th>#</th>
              <th>Company Name</th>
              <th>Address Details</th>
              <th>Contact Details</th>
              <th>Status</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentCompanies.map((company, index) => (
              <tr key={company.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedCompanies.includes(company.id)}
                    onChange={() => handleSelectCompany(company.id)}
                  />
                </td>
                <td>{(currentPage - 1) * companiesPerPage + index + 1}</td>
                <td>{company.name}</td>
                <td>{company.address}</td>
                <td>
                  <div>{company.contact.name}</div>
                  <div>{company.contact.phone}</div>
                  <div>{company.contact.email}</div>
                </td>
                <td style={{ backgroundColor: company.active ? '#d4edda' : '#f8d7da' }}>
                  {company.active ? (
                    <span className="text-success">Active</span>
                  ) : (
                    <span className="text-danger">Inactive</span>
                  )}
                </td>
                <td>
                  <span style={{ backgroundColor: company.active ? '#d4edda' : '#f8d7da' }}>
                    {company.active ? 'Yes' : 'No'}
                  </span>
                </td>
                <td className="text-end">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-view-${company.id}`}>View Details</Tooltip>}
                  >
                    <Button variant="outline-primary" size="sm" onClick={() => handleViewDetails(company.id)}>
                      <FeatherIcon icon="eye" size={16} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-branch-${company.id}`}>Manage Branches</Tooltip>}
                  >
                    <Button variant="outline-secondary" size="sm" onClick={() => handleBranch(company.id)}>
                      <FeatherIcon icon="layers" size={16} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-settings-${company.id}`}>Company Settings</Tooltip>}
                  >
                    <Button variant="outline-info" size="sm" onClick={() => handleCompanySettings(company.id)}>
                      <FeatherIcon icon="settings" size={16} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-approve-${company.id}`}>Approve</Tooltip>}
                  >
                    <Button variant="outline-success" size="sm" onClick={() => handleApproveReject(company.id, "Approve")}>
                      <FeatherIcon icon="check-circle" size={16} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-reject-${company.id}`}>Reject</Tooltip>}
                  >
                    <Button variant="outline-danger" size="sm" onClick={() => handleApproveReject(company.id, "Reject")}>
                      <FeatherIcon icon="x-circle" size={16} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-edit-${company.id}`}>Edit</Tooltip>}
                  >
                    <Button variant="outline-warning" size="sm" onClick={() => handleEdit(company.id)}>
                      <FeatherIcon icon="edit" size={16} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-delete-${company.id}`}>Delete</Tooltip>}
                  >
                    <Button variant="outline-danger" size="sm" onClick={() => handleDelete(company.id)}>
                      <FeatherIcon icon="trash" size={16} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-toggle-${company.id}`}>{company.active ? 'Deactivate' : 'Activate'}</Tooltip>}
                  >
                    <Button variant="outline-dark" size="sm" onClick={() => handleStatusChange(company.id)}>
                      <FeatherIcon icon={company.active ? "eye-off" : "eye"} size={16} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div>
          <Button
            variant="outline-secondary"
            onClick={() => handleBulkStatusChange(true)}
            disabled={selectedCompanies.length === 0}
          >
            Activate Selected
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => handleBulkStatusChange(false)}
            className="ms-2"
            disabled={selectedCompanies.length === 0}
          >
            Deactivate Selected
          </Button>
        </div>
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default CompanyList;
