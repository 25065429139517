import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthLayout from "./AuthLayout";
import FeatherIcon from "feather-icons-react";
import logoDark from "../../assets/images/Richway-Innovation-logo1.png";
import logoLight from "../../assets/images/Richway-Innovation-logo1.png";

const CompanyRegistration = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  return (
    <AuthLayout>
      <div className="auth-logo mx-auto">
        <Link to="/" className="logo logo-dark text-center">
          <span className="logo-lg">
            <img src={logoDark} alt="" height="55" />
          </span>
        </Link>

        <Link to="/" className="logo logo-light text-center">
          <span className="logo-lg">
            <img src={logoLight} alt="" height="55" />
          </span>
        </Link>
      </div>

      <h6 className="h5 mb-0 mt-3">{t("Company Registration")}</h6>
      <p className="text-muted mt-1 mb-4">
        {t("Fill in the details to register your company")}
      </p>

      <div className="mb-4">
        {step === 1 && <h4 className="header-title">{t("Company Information")}</h4>}
        {step === 2 && <h4 className="header-title">{t("Address Detail")}</h4>}
        {step === 3 && <h4 className="header-title">{t("Contact Information")}</h4>}
      </div>

      <Card>
        <Card.Body>
          <Form>
            {step === 1 && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("Name of the Company")}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("Enter your company name")}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("Company Type")}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="d-flex">
                    <Form.Check
                      type="radio"
                      label={t("Proprietor")}
                      name="companyType"
                      value="Proprietor"
                      required
                      className="me-3"
                    />
                    <Form.Check
                      type="radio"
                      label={t("LP")}
                      name="companyType"
                      value="LP"
                      required
                      className="me-3"
                    />
                    <Form.Check
                      type="radio"
                      label={t("PVT")}
                      name="companyType"
                      value="PVT"
                      required
                      className="me-3"
                    />
                    <Form.Check
                      type="radio"
                      label={t("Public")}
                      name="companyType"
                      value="Public"
                      required
                    />
                  </div>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("Nature of Business")}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control as="select" required>
                      <option>{t("Manufacturing")}</option>
                      <option>{t("Retail")}</option>
                      <option>{t("Service")}</option>
                      <option>{t("Technology")}</option>
                    </Form.Control>
                    <InputGroup.Text>
                      <FeatherIcon icon="chevron-down" />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("Company Size")}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="d-flex">
                    <Form.Check
                      type="radio"
                      label={t("Small")}
                      name="companySize"
                      value="Small"
                      required
                      className="me-3"
                    />
                    <Form.Check
                      type="radio"
                      label={t("Medium")}
                      name="companySize"
                      value="Medium"
                      required
                      className="me-3"
                    />
                    <Form.Check
                      type="radio"
                      label={t("Large")}
                      name="companySize"
                      value="Large"
                      required
                    />
                  </div>
                </Form.Group>
              </>
            )}

            {step === 2 && (
              <>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("Address 1")}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Enter address 1")}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Address 2")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Enter address 2")}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("Country")}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <InputGroup>
                        <Form.Control as="select" required>
                          <option>{t("Select Country")}</option>
                          {/* Add country options here */}
                        </Form.Control>
                        <InputGroup.Text>
                          <FeatherIcon icon="chevron-down" />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("State")}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <InputGroup>
                        <Form.Control as="select" required>
                          <option>{t("Select State")}</option>
                          {/* Add state options here */}
                        </Form.Control>
                        <InputGroup.Text>
                          <FeatherIcon icon="chevron-down" />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("City")}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <InputGroup>
                        <Form.Control as="select" required>
                          <option>{t("Select City")}</option>
                          {/* Add city options here */}
                        </Form.Control>
                        <InputGroup.Text>
                          <FeatherIcon icon="chevron-down" />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("PIN Code")}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Enter PIN code")}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Landmark")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Enter landmark")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            {step === 3 && (
              <>
                <Row>
                <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("Contact Person")}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <InputGroup>
                        <Form.Control as="select" required>
                          <option>{t("Mr.")}</option>
                          <option>{t("Ms.")}</option>
                        </Form.Control>
                        <Form.Control
                          type="text"
                          placeholder={t("Enter contact person name")}
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("Mobile No")}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Enter mobile number")}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("Phone No")}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Enter phone number")}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("Email ID")}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={t("Enter email address")}
                        required
                      />
                    </Form.Group>
                    
                  </Col>

                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("PIN Code")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Enter PIN code")}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("PAN No")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Enter PAN number")}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("TAN No")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Enter TAN number")}
                      />
                    </Form.Group>
                  </Col>
                </Row>

             
              </>
            )}

            <div className="d-flex justify-content-between mt-4">
              {step > 1 && (
                <Button variant="primary" onClick={handleBack}>
                  <FeatherIcon icon="arrow-left" className="me-2" />
                  {t("Back")}
                </Button>
              )}
              {step < 3 && (
                <Button variant="primary" onClick={handleNext}>
                  {t("Next")}
                  <FeatherIcon icon="arrow-right" className="ms-2" />
                </Button>
              )}
              {step === 3 && (
                <Button variant="success" type="submit">
                  {t("Submit")}
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </AuthLayout>
  );
};

export default CompanyRegistration;
