// src/pages/timeSheetTracking/TimeSheetEntry.tsx

import React, { useState } from 'react';


const TimeSheetEntry: React.FC = () => {
  const [entryDate, setEntryDate] = useState('');
  const [projectName, setProjectName] = useState('');
  const [task, setTask] = useState('');
  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState('');

  const handleSave = () => {
    // Add logic to save the entry
    console.log('Saved:', { entryDate, projectName, task, fromTime, toTime });
  };

  const handleClear = () => {
    setEntryDate('');
    setProjectName('');
    setTask('');
    setFromTime('');
    setToTime('');
  };

  const handleClose = () => {
    // Add logic to close the form, if necessary
    console.log('Closed');
  };
  
  return (
    <div className="time-sheet-entry">
      <h3>Add TimeSheet</h3>
      <form>
        <div className="form-row">
          <div className="form-group">
            <label>Entry Date</label>
            <input 
              type="date" 
              value={entryDate} 
              onChange={(e) => setEntryDate(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <label>Project Name</label>
            <input 
              type="text" 
              value={projectName} 
              onChange={(e) => setProjectName(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <label>Task</label>
            <input 
              type="text" 
              value={task} 
              onChange={(e) => setTask(e.target.value)} 
              required 
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>From Time</label>
            <input 
              type="time" 
              value={fromTime} 
              onChange={(e) => setFromTime(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <label>To Time</label>
            <input 
              type="time" 
              value={toTime} 
              onChange={(e) => setToTime(e.target.value)} 
              required 
            />
          </div>
        </div>
        <div className="form-actions">
          <button type="button" onClick={handleSave}>Save</button>
          <button type="button" onClick={handleClear}>Clear</button>
          <button type="button" onClick={handleClose}>Close</button>
        </div>
      </form>
    </div>
  );
};

export default TimeSheetEntry;
