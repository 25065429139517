import React, { useState } from 'react';
import { Modal, Button, Row, Col, Form, Table } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { useForm, Controller } from 'react-hook-form';

const data = [
  {
    id: 1,
    taskName: 'Checking Issue',
    entered: '29-May-2024',
    projectName: 'Jazztrack',
    entryDate: '14-May-2024',
    fromTime: '18:44',
    toTime: '18:46',
    status: 'Entered',
    history: {
      srNo: 1,
      status: 'Entered',
      statusUpdatedOn: '28-May-2024 18:43',
      statusUpdatedBy: 'Chankwinn',
      statusUpdatedRemarks: 'Entry is Done',
    },
    employeeName: 'John Doe', 
  },
  // Add more entries as needed
];

const TimeSheetEntryList = () => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupType, setPopupType] = useState<'edit' | 'approve' | 'disapprove' | 'add' | null>(null);
  const [selectedEntry, setSelectedEntry] = useState<any>(null);
  const { register, handleSubmit, reset, control } = useForm();

  const handleToggleHistory = (id: number) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  const handleActionClick = (type: 'edit' | 'approve' | 'disapprove' | 'add', entry?: any) => {
    setPopupType(type);
    setSelectedEntry(entry);
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setPopupType(null);
    setSelectedEntry(null);
    reset();
  };

  const handlePopupSave = (formData: any) => {
    // Implement your logic for saving data here.
    // You'll need to handle both adding and editing based on popupType.

    // Example: 
    // if (popupType === 'add') {
    //   // Logic for adding a new entry
    // } else {
    //   // Logic for updating an existing entry
    // }

    console.log('Saved data:', formData);
    setShowPopup(false);
    reset();
  };

  const handleSearch = (data: any) => {
    console.log('Search criteria:', data);
  };

  return (
    <div className="container">
      <Modal show={showPopup} onHide={handlePopupClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {popupType === 'edit' ? 'Edit Time Sheet Entry' :
             popupType === 'add' ? 'Add Time Sheet Entry' :
             'Approve/Disapprove Time Sheet Entry'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {popupType === 'edit' && (
            <Form onSubmit={handleSubmit(handlePopupSave)} className="px-2">
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="entryDate">
                    <Form.Label>Entry Date</Form.Label>
                    <Controller
                      name="entryDate"
                      control={control}
                      defaultValue={selectedEntry?.entryDate}
                      render={({ field }) => <Form.Control type="date" {...field} />}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="projectName">
                    <Form.Label>Project Name</Form.Label>
                    <Form.Control as="select" {...register('projectName')}>
                      <option value="one">Project One</option>
                      <option value="two">Project Two</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="task">
                    <Form.Label>Task</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter task"
                      {...register('task')}
                      defaultValue={selectedEntry?.taskName}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="fromTime">
                    <Form.Label>From Time</Form.Label>
                    <Form.Control
                      type="time"
                      {...register('fromTime')}
                      defaultValue={selectedEntry?.fromTime}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="toTime">
                    <Form.Label>To Time</Form.Label>
                    <Form.Control
                      type="time"
                      {...register('toTime')}
                      defaultValue={selectedEntry?.toTime}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-center mt-4">
                <Button variant="success" type="submit" className="me-2">
                  Save
                </Button>
                <Button variant="danger" type="reset" className="me-2" onClick={() => reset()}>
                  Clear
                </Button>
                <Button variant="secondary" onClick={handlePopupClose}>
                  Close
                </Button>
              </div>
            </Form>
          )}
          {popupType === 'add' && (
            <Form onSubmit={handleSubmit(handlePopupSave)} className="px-2">
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="entryDate">
                    <Form.Label>Entry Date</Form.Label>
                    <Controller
                      name="entryDate"
                      control={control}
                      render={({ field }) => <Form.Control type="date" {...field} />}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="projectName">
                    <Form.Label>Project Name</Form.Label>
                    <Form.Control as="select" {...register('projectName')}>
                      <option value="one">Project One</option>
                      <option value="two">Project Two</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="task">
                    <Form.Label>Task</Form.Label>
                    <Form.Control type="text" placeholder="Enter task" {...register('task')} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="fromTime">
                    <Form.Label>From Time</Form.Label>
                    <Form.Control type="time" {...register('fromTime')} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="toTime">
                    <Form.Label>To Time</Form.Label>
                    <Form.Control type="time" {...register('toTime')} />
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-center mt-4">
                <Button variant="success" type="submit" className="me-2">
                  Save
                </Button>
                <Button variant="danger" type="reset" className="me-2" onClick={() => reset()}>
                  Clear
                </Button>
                <Button variant="secondary" onClick={handlePopupClose}>
                  Close
                </Button>
              </div>
            </Form>
          )}
          {(popupType === 'approve' || popupType === 'disapprove') && (
            <div className="popup-content">
              {selectedEntry && (
                <Table striped bordered hover size="sm" className={popupType === 'approve' ? 'approve-table' : 'disapprove-table'}>
                  <tbody>
                    <tr>
                      <td>Employee Name:</td>
                      <td>{selectedEntry.employeeName}</td>
                    </tr>
                    <tr>
                      <td>Task:</td>
                      <td>{selectedEntry.taskName}</td>
                    </tr>
                    <tr>
                      <td>Project Name:</td>
                      <td>{selectedEntry.projectName}</td>
                    </tr>
                    <tr>
                      <td>Entry Date:</td>
                      <td>{selectedEntry.entryDate}</td>
                    </tr>
                    <tr>
                      <td>From:</td>
                      <td>{selectedEntry.fromTime}</td>
                    </tr>
                    <tr>
                      <td>To:</td>
                      <td>{selectedEntry.toTime}</td>
                    </tr>
                    <tr>
                      <td>Remarks:</td>
                      <td>{selectedEntry.history.statusUpdatedRemarks}</td>
                    </tr>
                  </tbody>
                </Table>
              )}
              <p>Are you sure you want to {popupType === 'approve' ? 'approve' : 'disapprove'} this entry?</p>
              <Form.Group controlId="remarks"> 
                <Form.Label>Remarks:</Form.Label>
                <Form.Control as="textarea" rows={3} {...register('remarks')} />
              </Form.Group>
              <div className="button-row">
                <Button variant="success" onClick={() => handlePopupSave({ status: popupType })}>
                  {popupType === 'approve' ? 'Approve' : 'Disapprove'}
                </Button>
                <Button variant="secondary" onClick={handlePopupClose}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>



      <div className="action-buttons text-center mb-3">
        <Button
          variant="primary"
          title="Add Time Sheet"
          onClick={() => handleActionClick('add')} 
          className="me-2"
        >
          <FeatherIcon icon="plus" size={16} /> Add Time Sheet
        </Button>
     
      </div>

      <div className="search-container">
        <p className="filter-caption">Time Sheet Entry Filter</p>
        <Form onSubmit={handleSubmit(handleSearch)} className="filter-form px-2">
          <Row className="align-items-end">
            <Col md={3}>
              <Form.Group className="mb-3" controlId="projectFilter">
                <Form.Label>Project</Form.Label>
                <Form.Control as="select" {...register('project')}>
                  <option value="">Select Project</option>
                  <option value="Jazztrack">Jazztrack</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3" controlId="statusFilter">
                <Form.Label>Status</Form.Label>
                <Form.Control as="select" {...register('status')}>
                  <option value="">Select Status</option>
                  <option value="Entered">Entered</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3" controlId="fromDateFilter">
                <Form.Label>From Date</Form.Label>
                <Form.Control type="date" {...register('fromDate')} />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3" controlId="toDateFilter">
                <Form.Label>To Date</Form.Label>
                <Form.Control type="date" {...register('toDate')} />
              </Form.Group>
            </Col>
          </Row>
          <div className="text-center">
            <Button type="submit" variant="secondary" className="me-2">
              <FeatherIcon icon="search" size={16} /> Search
            </Button>
            <Button variant="danger" onClick={() => reset()}>
              Clear
            </Button>
          </div>
        </Form>
      </div>
      <div className="action-buttons text-center mb-3">
        
        <Button
          variant="secondary"
          title="Export to Excel"
          onClick={() => alert('Export to Excel functionality to be implemented')}
          className="me-2"
        >
          <FeatherIcon icon="file" size={16} /> Excel
        </Button>
        <Button
          variant="secondary"
          title="Export to PDF"
          onClick={() => alert('Export to PDF functionality to be implemented')}
        >
          <FeatherIcon icon="file-text" size={16} /> PDF
        </Button>
      </div>
      <table className="entry-table">
        <thead>
          <tr>
            <th style={{ background: '#f0f0f0' }}>Sr No</th>
            <th style={{ background: '#f0f0f0' }}>Task Name</th>
            <th style={{ background: '#f0f0f0' }}>Entered</th>
            <th style={{ background: '#f0f0f0' }}>Project Name</th>
            <th style={{ background: '#f0f0f0' }}>Entry Date</th>
            <th style={{ background: '#f0f0f0' }}>From Time</th>
            <th style={{ background: '#f0f0f0' }}>To Time</th>
            <th style={{ background: '#f0f0f0' }}>Status</th>
            <th style={{ background: '#f0f0f0' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((entry, index) => (
            <React.Fragment key={entry.id}>
              <tr>
                <td>{index + 1}</td>
                <td>{entry.taskName}</td>
                <td>{entry.entered}</td>
                <td>{entry.projectName}</td>
                <td>{entry.entryDate}</td>
                <td>{entry.fromTime}</td>
                <td>{entry.toTime}</td>
                <td>{entry.status}</td>
                <td>
                  <FeatherIcon
                    icon="edit"
                    size={16}
                    className="icon"
                    title="Edit"
                    onClick={() => handleActionClick('edit', entry)}
                  />
                  <FeatherIcon
                    icon="check-circle"
                    size={16}
                    className="icon"
                    title="Approve"
                    onClick={() => handleActionClick('approve', entry)}
                  />
                  <FeatherIcon
                    icon="x-circle"
                    size={16}
                    className="icon"
                    title="Disapprove"
                    onClick={() => handleActionClick('disapprove', entry)}
                  />
                  <FeatherIcon
                    icon="file-text"
                    size={16}
                    className="icon"
                    title="History"
                    onClick={() => handleToggleHistory(entry.id)}
                  />
                </td>
              </tr>
              {expandedRow === entry.id && (
                <tr className="expanded-row">
                  <td colSpan={9}>
                    <div className="history-details">
                      <table className="history-table">
                        <thead>
                          <tr>
                            <th>Sr No</th>
                            <th>Status</th>
                            <th>Status Updated On</th>
                            <th>Status Updated By</th>
                            <th>Status Updated Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{entry.history.srNo}</td>
                            <td>{entry.history.status}</td>
                            <td>{entry.history.statusUpdatedOn}</td>
                            <td>{entry.history.statusUpdatedBy}</td>
                            <td>{entry.history.statusUpdatedRemarks}</td>
                          </tr>
                        </tbody>
                      </table>
                      <button
                        className="btn btn-secondary"
                        onClick={() => handleToggleHistory(entry.id)}
                      >
                        Close
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <style>{`
        .filters {
          margin-bottom: 20px;
          padding-top: 20px;
          border-top: 1px solid #ddd;
        }

        .search-container {
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          padding: 15px;
          margin-bottom: 20px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        .entry-table th {
          background-color: #f0f0f0;
        }

        .popup {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: white;
          border: 1px solid #ccc;
          padding: 20px;
          z-index: 1000;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          width: 400px;
        }

        .popup-content {
          display: flex;
          flex-direction: column;
        }

        .form {
          margin-top: 20px;
        }

        .row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }

        .field {
          display: flex;
          flex-direction: column;
        }

        .button-row {
          display: flex;
          justify-content: space-around;
          margin-top: 20px;
        }

        .filter-caption {
          font-weight: bold;
          margin-bottom: 10px;
        }

        .action-buttons {
          margin-bottom: 20px;
        }

        .icon {
          margin-right: 10px;
          cursor: pointer;
        }

        .approve-table {
          background-color: #e0ffe0; /* Light Green - Approving */
        }

        .disapprove-table {
          background-color: #ffe0e0; /* Light Red - Rejecting */
        }
      `}</style>
    </div>
  );
};

export default TimeSheetEntryList;
